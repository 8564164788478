import Footer from '../../components/partials/Footer'

function Init(props) {

  return (
    <div>
      {props.children}

      <Footer />
    </div>
  )
}

export default Init
