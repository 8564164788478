export default function Banner() {
  return (
    <>
      <section className="slider-one">
        <div className="slider-one__carousel owl-carousel owl-theme">
          <div
            className="item slider-one__slider-1"
            style={{ backgroundImage: "url(images/slider/slider-1-1.jpg)" }}
          >
            <div className="container text-center">
              <p className="slider-one__tag-line text-uppercase">
                USTAARABUU
              </p>
              {/* /.slider-one__tag-line */}
              <h2 className="slider-one__title">
                Centre Mondial de Recherches et de Pratiques sur les Humanités Classiques Africaines
              </h2>
              {/* /.slider-one__title */}
              <p className="slider-one__text ">
                cheminement Initiatique Finangnon 01 (Jeudi) - 16 (Vendredi), Aout 2024.
              </p>
              {/* /.slider-one__text */}
              <a href="#" className="thm-btn slider-one__btn">
               En Savoir Plus
              </a>
              {/* /.thm-btn slider-one__btn */}
            </div>
            {/* /.container */}
          </div>
          {/* /.item */}
          <div
            className="item slider-one__slider-2"
            style={{ backgroundImage: "url(images/slider/slider-1-2.jpg)" }}
          >
           <div className="container text-center">
              <p className="slider-one__tag-line text-uppercase">
                USTAARABUU
              </p>
              {/* /.slider-one__tag-line */}
              <h2 className="slider-one__title">
                Centre Mondial de Recherches et de Pratiques sur les Humanités Classiques Africaines
              </h2>
              {/* /.slider-one__title */}
              <p className="slider-one__text ">
                cheminement Initiatique Finangnon 01 (Jeudi) - 16 (Vendredi), Aout 2024.
              </p>
              {/* /.slider-one__text */}
              <a href="#" className="thm-btn slider-one__btn">
               En Savoir Plus
              </a>
              {/* /.thm-btn slider-one__btn */}
            </div>
            {/* /.container */}
          </div>
          {/* /.item */}
          <div
            className="item slider-one__slider-3"
            style={{ backgroundImage: "url(images/slider/slider-1-3.jpg)" }}
          >
            <div className="container text-center">
              <p className="slider-one__tag-line text-uppercase">
                USTAARABUU
              </p>
              {/* /.slider-one__tag-line */}
              <h2 className="slider-one__title">
                Centre Mondial de Recherches et de Pratiques sur les Humanités Classiques Africaines
              </h2>
              {/* /.slider-one__title */}
              <p className="slider-one__text ">
                cheminement Initiatique Finangnon 01 (Jeudi) - 16 (Vendredi), Aout 2024.
              </p>
              {/* /.slider-one__text */}
              <a href="#" className="thm-btn slider-one__btn">
               En Savoir Plus
              </a>
              {/* /.thm-btn slider-one__btn */}
            </div>
            {/* /.container */}
          </div>
          {/* /.item */}
        </div>
        {/* /.slider-one__carousel */}
        <div className="slider-one__nav">
          <a className="slider-one__nav-left slide-one__left-btn" href="#">
            <i className="egypt-icon-right-angle" />
            <span className="slider-one__nav-text">Prev</span>
            {/* /.slider-one__nav-text */}
          </a>
          <a className="slider-one__nav-right slide-one__right-btn" href="#">
            <i className="egypt-icon-left-angle" />
            <span className="slider-one__nav-text">Next</span>
            {/* /.slider-one__nav-text */}
          </a>
        </div>
        {/* /.slider-two__nav */}
      </section>
      {/* /.slider-one */}
    </>
  );
}
