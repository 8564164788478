import Banner from '../../components/Banner'
import Header from '../../components/partials/Header'
import TopBar from '../../components/partials/TopBar'

function Home() {
  return (
    <>
      <div className="page-wrapper">

         {/*    <TopBar /> */}
            <Header />
            <Banner />
      </div>
     
  

     
    </>
  )
}

export default Home
