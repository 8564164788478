function Header() {
  return (
    <>
    <header className="site-header site-header__header-one  ">
      <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
        <div className="container clearfix">
          {/* Brand and toggle get grouped for better mobile display */}
          <div className="logo-box">
            <a className="navbar-brand" href="index.html">
              <img
                src="logo.png"
                className="main-logo"
                width={"70px"}
                height={"70px"}
                alt="Awesome Image"
              />
            </a>
            <button className="menu-toggler" data-target=".main-navigation">
              <span className="fa fa-bars" />
            </button>
          </div>
          {/* /.logo-box */}
          {/* Collect the nav links, forms, and other content for toggling */}
          <div className="main-navigation">
            <ul className=" navigation-box @@extra_class">
             {/*  <li className="current">
                <a href="index.html">Home</a>
                <ul className="submenu">
                  <li>
                    <a href="index.html">Home One</a>
                  </li>
                  <li>
                    <a href="index-2.html">Home Two</a>
                  </li>
                  <li>
                    <a href="index.html">Header Variations</a>
                    <ul className="submenu">
                      <li>
                        <a href="index.html">Home One</a>
                      </li>
                      <li>
                        <a href="index-2.html">Home Two</a>
                      </li>
                    </ul>
                  
                  </li>
                </ul>
               
              </li> */}

              <li className="current">
              <marquee behavior="scroll" direction="left" loop="-1"> 
                <b>               ⚠️  INFORMATION : CE SITE EST EN COURS DE MAINTENANCE VEUILLEZ REVENIR PLUS TARD MERCI ! ⚠️ </b>
           
              </marquee>
 
              </li>
              
            </ul>
          </div>
          {/* /.navbar-collapse */}
          <div className="right-side-box">
            <a href="cart.html" className="site-header__cart">
             {/*  <i className="egypt-icon-supermarket" /> */}
              {/* /.egypt-icon-supermarket */}{/*  <span className="count">3</span> */}
              {/* /.count */}
            </a>
            {/* /.site-header__cart */}
            <a href="#" className="site-header__sidemenu-nav side-menu__toggler">
              <span className="site-header__sidemenu-nav-line" />
              {/* /.site-header__sidemenu-nav-line */}
              <span className="site-header__sidemenu-nav-line" />
              {/* /.site-header__sidemenu-nav-line */}
              <span className="site-header__sidemenu-nav-line" />
              {/* /.site-header__sidemenu-nav-line */}
            </a>
            {/* /.site-header__sidemenu */}
          </div>
          {/* /.right-side-box */}
        </div>
        {/* /.container */}
      </nav>
    </header>
    {/* /.site-header */}
  </>
  
  )
}
export default Header
